/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@use '@angular/material' as mat;


$blue-app-primary: mat.define-palette(mat.$indigo-palette, 600);
$blue-app-accent: mat.define-palette(mat.$blue-palette, 600);
$blue-app-warn: mat.define-palette(mat.$pink-palette);
// Create the theme object (a Sass map containing all of the palettes).
$blue-app-theme: mat.define-light-theme($blue-app-primary, $blue-app-accent, $blue-app-warn);
$themecolor:#1E88E5;
$themecolor-alt: #1E88E5;
$white:#fff;
#snav {
    .mat-nav-list .mat-list-item { 
        .mat-list-item-content {
            &:hover {
                > a {
                    color: $themecolor;
                    
                }
            }
        }
        &.selected > .mat-list-item-content > a {
            background: $themecolor-alt;
            color: $white;
            > mat-icon {
                color: #fff !important;
            }
        }
        &.selected > .dd-icon {
            color:#fff;
        }
    }
}