/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. Posts
 *	7. Widgets
 *	8. Custom Templates
 */
/******************* 
Global Styles 
*******************/

* {
    outline: none;
    box-sizing:border-box;
}

body {
    font-family: $bodyfont;
    margin: 0;
    overflow-x: hidden;
    color: $bodytext;
    font-weight: 300;
    background: $inverse;
}

html {
    position: relative;
    min-height: 100%;
    background: $white;
}
a:hover, a:focus, a{
    text-decoration: none;
}
a.link{
    color:$headingtext;
    text-decoration: none;
    &:hover, &:focus{
        color:$themecolor;
    }
}
.img-responsive{
    width: 100%;
    height: auto;
    display: inline-block;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-rounded{
    border-radius: $radius;
}
html body .mdi:before, html body .mdi-set{
    line-height: initial;
}
code {
    padding: .2rem .4rem;
    font-size: 90%;
    color: $danger;
    background-color: $light-danger;
    border-radius: .25rem;
}

.shadow-none{
    box-shadow: none!important; 
}
.ml-auto{
    margin-left: auto;
}
.m-auto{
    margin: auto;
}
.mr-auto{
    margin-right: auto;
}
.rounded{
    border-radius: $radius;
}
/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
    
    font-family: $headingfont;
    font-weight: 400;
}

h1 {
    line-height: 40px;
    font-size: 36px
}

h2 {
    line-height: 36px;
    font-size: 30px
}

h3 {
    line-height: 30px;
    font-size: 21px
}

h4 {
    line-height: 22px;
    font-size: 18px
}

h5 {
    line-height: 18px;
    font-size: 16px;
    font-weight: 400;
}

h6 {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
}
html, body{
.display-5{
    font-size:3rem;
}
.display-6{
    font-size:36px;
}
}    
.box{
    border-radius: $radius;
    padding: 10px;
}
html body {
.dl{
    display: inline-block;
}
.db{
    display: block;
}
    .op-5{
        opacity: 0.5
    }
    .op-3{
        opacity: 0.3;
    }
}    
.no-wrap{
    td, th{
        white-space: nowrap;
    }
}
.relative{
    position: relative;
}
.absolute{
    position: absolute;
}
.d-flex{
    display: flex;
 }
.no-shrink{
    flex-shrink: 0;
}
.oh{
    overflow: hidden;
}
/*******************
Blockquote
*******************/

html body blockquote {
    
    border: 1px solid $border;
    border-left: 5px solid $themecolor;
    padding: 15px;
    margin: 20px 0;
}

.clear {
    clear: both;
}
ol li{
    margin: 5px 0;
}

/*******************
Paddings
*******************/
html body {
.p-0 {
    padding: 0px;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}

.p-30 {
    padding: 30px;
}

.p-l-0 {
    padding-left: 0px;
}

.p-l-10 {
    padding-left: 10px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-r-0 {
    padding-right: 0px;
}

.p-r-10 {
    padding-right: 10px;
}

.p-r-20 {
    padding-right: 20px;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-40 {
    padding-right: 40px;
}

.p-t-0 {
    padding-top: 0px;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-20 {
    padding-top: 20px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-b-0 {
    padding-bottom: 0px;
}

.p-b-5 {
    padding-bottom: 5px;
}

.p-b-10 {
    padding-bottom: 10px;
}

.p-b-20 {
    padding-bottom: 20px;
}

.p-b-30 {
    padding-bottom: 30px;
}

.p-b-40 {
    padding-bottom: 40px;
}

.p-l-30 {
	padding-left: 30px;
}

.p-r-30 {
	padding-right: 30px;
}
}

/*******************
Margin
*******************/
html body {
.m-0 {
    margin: 0px;
}

.m-l-5 {
    margin-left: 5px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-15 {
    margin-left: 15px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-30 {
    margin-left: 30px;
}

.m-l-40 {
    margin-left: 40px;
}

.m-r-5 {
    margin-right: 5px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-15 {
    margin-right: 15px;
}

.m-r-20 {
    margin-right: 20px;
}

.m-r-30 {
    margin-right: 30px;
}

.m-r-40 {
    margin-right: 40px;
}

.m-t-0 {
    margin-top: 0px;
}

.m-t-5 {
    margin-top: 5px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-40 {
    margin-top: 40px;
}

.m-b-0 {
    margin-bottom: 0px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-30 {
    margin-bottom: 30px ;
}

.m-b-40 {
    margin-bottom: 40px;
}
}

/*******************
vertical alignment
*******************/
html body {
.vt {
    vertical-align: top;
}

.vm {
    vertical-align: middle;
}

.vb {
    vertical-align: bottom; 
}
}    
/*******************
Opacity
*******************/
.op-5{
    opacity: 0.5;
}
/*******************
font weight
*******************/
html body {
.font-bold {
    font-weight: 700;
}

.font-normal {
    font-weight: normal;
}

.font-light {
    font-weight: 300;
}
.font-medium {
    font-weight: 500;
}
.font-16 {
    font-size: 16px;
}
.font-14 {
    font-size: 14px;
}
.font-10 {
    font-size: 10px;
}
.font-12 {
    font-size: 12px;
}    
.font-18 {
    font-size: 18px;
}
.font-20 {
    font-size: 20px;
    }
}
/*******************
Border
*******************/
html body {
.b-0{
    border:none;
}
.b-r{
    border-right:1px solid $border;
}
.b-l{
    border-left:1px solid $border;
}
.b-b{
    border-bottom:1px solid $border;
}
.b-t{
    border-top:1px solid $border;
}
.b-all{
    border:1px solid $border;
}
}
/*******************
Thumb size
*******************/

.thumb-sm {
    height: 32px;
    width: 32px;
}

.thumb-md {
    height: 48px;
    width: 48px;
}

.thumb-lg {
    height: 88px;
    width: 88px;
}
.hide{
    display: none;
}
.img-circle{
    border-radius: 100%;
}
.radius{
    border-radius: $radius;
}
/*******************
Text Colors
*******************/
.text-white {
    color: $white!important;
}

.text-danger {
    color: $danger!important;
}

.text-muted {
    color: rgba(0, 0, 0, 0.54);
}

.text-warning {
    color: $warning!important;
}

.text-success {
    color: $success!important;
}

.text-info {
    color: $info!important;
}

.text-inverse {
    color: $inverse!important;
}
html body {
.text-blue {
    color: $blue;
}

.text-purple {
    color: $purple;
}

.text-primary {
    color: $primary;
}

.text-megna {
    color: $megna;
}

.text-dark {
    color: $bodytext;
}
.text-themecolor {
    color: $themecolor;
}
    .text-center{
        text-align: center;
    }
    .text-right{
        text-align: right;
        
    }
    .text-left{
        text-align: left;
    }
}
/*******************
Background Colors
*******************/

.bg-primary {
    background-color: $primary !important;
}

.bg-success {
    background-color: $success !important;
}

.bg-info {
    background-color: $info !important;
}

.bg-warning {
    background-color: $warning !important;
}

.bg-danger {
    background-color: $danger !important;
}
html body {
.bg-megna {
    background-color: $megna;
}
.bg-theme {
    background-color: $themecolor;
}

.bg-inverse {
    background-color: $inverse;
}

.bg-purple {
    background-color: $purple!important;
}

.bg-light-primary {
    background-color: $light-primary;
}

.bg-light-success {
    background-color: $light-success;
}

.bg-light-info {
    background-color: $light-info;
}
.bg-light-extra {
    background-color: $extra-light;
}

.bg-light-warning {
    background-color: $light-warning;
}

.bg-light-danger {
    background-color: $light-danger;
}

.bg-light-inverse {
    background-color: $light-inverse;
}
.bg-light {
    background-color: $light;
}
.bg-white {
    background-color: $white;
}
    }
.img-circle{
    border-radius: 100%;
}
.no-shadow{
    box-shadow: none;
}
hr{
    border: 0px;
    height:1px;
    background: $border;
}
/*******************
Rounds
*******************/
.round{
    line-height:48px;
    color: $white;
    width: 45px;
    height: 45px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border-radius: 100%;
    background: $info;
    img{
        border-radius: 100%;
    }
}
.round-lg{
    line-height:65px;
    width: 60px;
    height: 60px;
    font-size:30px;
}
.round.round-info{
    background: $info;
}
.round.round-warning{
    background: $warning;
}
.round.round-danger{
    background: $danger;
}
.round.round-success{
    background: $success;
}
.round.round-primary{
    background: $primary;
}
/*******************
Labels
*******************/

.label {
    padding: 3px 10px;
    line-height: 13px;
    color: $white;
    font-weight: 400;
    border-radius: $radius;
    font-size: 75%;
}

.label-rounded {
    border-radius: 60px;
}

.label-custom {
    background-color: $megna;
}

.label-success {
    background-color: $success;
}

.label-info {
    background-color: $info;
}

.label-warning {
    background-color: $warning;
}

.label-danger {
    background-color: $danger;
}

.label-megna {
    background-color: $megna;
}

.label-primary {
    background-color: $primary;
}

.label-purple {
    background-color: $purple;
}

.label-red {
    background-color: $red;
}

.label-inverse {
    background-color: $inverse;
}

.label-default {
    background-color: $light;
}

.label-white {
    background-color: $white;
}


.label-light-success {
    background-color: $light-success;
    color:$success;
}

.label-light-info {
    background-color: $light-info;
    color:$info;
}

.label-light-warning {
    background-color: $light-warning;
    color:$warning;
}

.label-light-danger {
    background-color: $light-danger;
    color:$danger;
}

.label-light-megna {
    background-color: $light-megna;
    color:$megna;
}

.label-light-primary {
    background-color: $light-primary;
    color:$primary;
}

.label-light-inverse {
    background-color: $light-inverse;
    color:$inverse;
}



/*******************
Notify
*******************/

.notify {
    position: relative;
    top: -20px;
    right:0px;
    .heartbit {
        position: absolute;
        top: -20px;
        right: -4px;
        height: 25px;
        width: 25px;
        
        border: 5px solid $danger;
        border-radius: 70px;
        -moz-animation: heartbit 1s ease-out;
        -moz-animation-iteration-count: infinite;
        -o-animation: heartbit 1s ease-out;
        -o-animation-iteration-count: infinite;
        -webkit-animation: heartbit 1s ease-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }
    .point {
        width: 6px;
        height: 6px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        background-color: $danger;
        position: absolute;
        right: 6px;
        top: -10px;
    }
}

@-moz-keyframes heartbit {
    0% {
        -moz-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -moz-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -moz-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -moz-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 0.0;
    }
}

@-webkit-keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -webkit-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -webkit-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0.0;
    }
}
.btn-block{
    display: block;
    width: 100%;
}
.btn-lg{
    line-height: 48px!important;
}
/*******************
froms
*******************/
.form-control{
    border: 0px;
    line-height: 25px;
    padding: 10px;
    width: 100%;
    font-size:$font-16;
}
.mat-form-field-underline{
    background-color: rgba(0, 0, 0, 0.25);
}
.mat-form-field{
   width: 100%;
}
/*******************
Mat card
*******************/
.mat-card{
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05)!important;  
    -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05)!important; 
    margin: 15px;
    padding: 0px!important;
    border-radius: $radius;
    
    .mat-card-content{
        font-size:1rem;
        line-height: 23px;
        padding: 24px;    
    }
    .mat-card-image {
        width: calc(100% + 0px);
        margin: 0 0px 0px 0px;
    }
    .mat-card-header{
        padding: 12px;
        border-radius: $radius $radius 0 0;
        .mat-card-title{
            margin-bottom: 2px;
            font-size: 20px;
        }
        .mat-card-subtitle, .mat-card-subtitle:not(:first-child){
            margin-bottom: 0px;
            margin-top: 0px;
            font-size: 14px;
        }
        &.text-white{
           .mat-card-title{
            color:$white;
            }
            .mat-card-subtitle{
                color:rgba(255, 255, 255, 0.7);
            }
        }
    }
    .mat-card-title{
        font-size: 20px;
        margin-bottom: 5px;
    }
    .mat-card-subtitle{
        margin-bottom: 20px;
    }
    .mat-card-actions{
        margin: 0px!important;
    }
}

/*******************
Grid
*******************/
.row{
    margin: 0 -10px;
}
/*******************
Table
*******************/
.table{
    width: 100%;
    text-align: left;
}
.table-rasponsive{
    overflow:auto;
    .mat-table{
        min-width: 280px;
    }
    .mat-header-row{
        .mat-header-cell{
            white-space: nowrap;
        }
    }
    .mat-cell{
            white-space: nowrap;
        }
}
/*******************
Sparkline chart tooltip
*******************/
.jqstooltip{
  width: auto !important;
  height: auto !important;
  background: $inverse;
  color: $white;
  padding: 5px 15px; 
  border: 0px;
  border-radius: 60px;     
}
/*******************
list inline
*******************/
.list-inline{
    margin-left: 0px;
    padding: 0px;
    li{
        display: inline-block;
        list-style: none;
        margin-right: 10px;
        &:last-child{
            margin-right: 0px;
        }
        
    }
}
.button-row button{
    margin-right:2px;
    margin-left:2px;
}

.mini-spacer {
	margin-top: 90px;
}

/*******************
List-style-none
******************/

ul.list-style-none {
    margin: 0px;
    padding: 0px;
    li {
        list-style: none;
        a {
            color: $themecolor;
            padding: 8px 0px;
            display: block;
            text-decoration: none;
            &:hover {
                color: $themecolor;
            }
        }
    }
}

.align-self-center {
    align-self: center;
}

.float-right {
	float: right;
}

.page-breadcrumb {
    .breadcrumb {
        padding: 0px;
        margin: 0px;
        background: transparent;
		.breadcrumb-item {
			font-size: 14px;
		}
		.breadcrumb-item a {
			padding-right: .5rem;
		}
        .breadcrumb-item+.breadcrumb-item::before {
            content: "\e649";
            font-family: themify;
            color: $sidebar-icons;
            font-size: 11px;
			padding-right: .5rem;
        }
		.breadcrumb-item+.breadcrumb-item.active {
			color: $sidebar-icons;
		}
    }
}

.page-breadcrumb .breadcrumb .breadcrumb-item a{
    display:initial;
}